import { makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    left: 0,
    top: "calc(50% - 50px)",
    height: "50px",
    zIndex: 1200,
    background: "rgba(255,255,255, 0.4)",
    writingMode: "vertical-rl",
    textOrientation: "upright",
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "monospace",
    "&:hover": {
      background: "rgba(255,255,255, 0.8)"
    }
  },
  drawer: {
    position: "relative"
  },
  closeContainer: {
    height: "25px",
    width: "100%"
  },
  closeIcon: {
    position: "absolute",
    right: "5px"
  },
  list: {
    height: "calc(100vh - 25px)",
    overflowY: "auto",
    overflowX: "hidden"
  }
}));
