// default value
import { actionType } from "../stateType.types";

const defaultValue: number[] = [];

// constants
const SET_ACTIVE_RECORDS = "SET_ACTIVE_RECORDS";

// actions
const setallRecords = (records: number[]): actionType => {
  return { type: SET_ACTIVE_RECORDS, payload: records };
};

export default {
  defaultValue,
  actions: {
    SET_ACTIVE_RECORDS
  },
  actionCreators: {
    setallRecords
  }
};
