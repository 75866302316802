// default value
import { actionType } from "../stateType.types";

export type FilteredRecordType = {
  active: boolean;
  id: number;
}

const defaultValue: FilteredRecordType[] = [];

// constants
const SET_FILTERED_RECORDS = "SET_FILTERED_RECORDS";
const RESET_FILTERED_RECORDS = "SET_FILTERED_RECORDS";
const CHANGE_FILTERED_RECORD_STATE = "CHANGE_FILTERED_RECORD_STATE";

// actions
const setFilteredRecords = (allRecords: FilteredRecordType[]): actionType => {
  return {
    type: SET_FILTERED_RECORDS,
    payload: allRecords
  };
};

const changeFilteredRecordState = (
  recordIndex: number,
  value: boolean
): actionType => {
  return {
    type: CHANGE_FILTERED_RECORD_STATE,
    payload: { recordIndex, value }
  };
};

const resetFilteredRecords = (): actionType => {
  return { type: SET_FILTERED_RECORDS };
};

export default {
  defaultValue,
  actions: {
    SET_FILTERED_RECORDS,
    RESET_FILTERED_RECORDS,
    CHANGE_FILTERED_RECORD_STATE
  },
  actionCreators: {
    setFilteredRecords,
    resetFilteredRecords,
    changeFilteredRecordState
  }
};
