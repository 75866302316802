import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { LocalHospital } from "@material-ui/icons/";

const intensityMarker = {
  background: "rgba(97,255,0,0.6)",
  borderRadius: "50%",
  lineHeight: "25px",
  textAlign: "center",
  fontWeight: "bold"
};

export const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    height: "100%",
    "& .leaflet-container": {
      width: "100%",
      height: "100%"
    }
  },
  clusterMoreThan10: {
    width: "75px!important",
    height: "75px!important",
    lineHeight: "75px",
    textAlign: "center",
    background: "rgba(255,0,0,0.8)",
    borderRadius: "50%"
  },
  cluster5and10: {
    width: "50px!important",
    height: "50px!important",
    lineHeight: "50px",
    textAlign: "center",
    background: "rgba(255,0,0,0.6)",
    borderRadius: "50%"
  },
  clusterSmallGroup: {
    width: "35px!important",
    height: "35px!important",
    lineHeight: "35px",
    textAlign: "center",
    background: "rgba(255,0,0,0.4)",
    borderRadius: "50%"
  },
  singleMarker: {
    width: "25px!important",
    height: "25px!important",
    lineHeight: "25px",
    textAlign: "center",
    background: "rgba(0,0,255,0.8)",
    fontWeight: "bold",
    borderRadius: "50%",
    color: "white"
  }
}));
