import React, { FC, useEffect, useState } from "react";
import { useStyles } from "./CountrySelector.styles";
import { ArrowRightIcon } from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import {
  Checkbox,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer
} from "@material-ui/core";
import { ReduxState } from "../../../store/reducers";
import { LocationRecord } from "../../../store/states/locationRecord";
import { FeedRecord } from "../../../store/states/feedRecord";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import filteredRecords, {
  FilteredRecordType
} from "../../../store/states/filteredRecords";
import CountryListItem, {
  CountryListItemType
} from "../../Atoms/CountryListItem";
import activeRecord from "../../../store/states/activeRecord";
import { Close } from "@material-ui/icons";

type StateProps = {
  allRecords: number[];
  locationRecords: LocationRecord[];
  filteredRecords: FilteredRecordType[];
};

const CountrySelector: FC<ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>> = ({
  allRecords,
  locationRecords,
  filteredRecords,
  setFilteredRecords,
  changeFilteredRecordState,
  resetFilteredRecords
}): JSX.Element => {
  const classes = useStyles();
  const [drawerState, setDrawerState] = useState<boolean>(false);

  const [optionList, setOptionList] = useState<CountryListItemType[]>([]);
  const [checkedIndex, setCheckedIndex] = useState<number[]>(
    filteredRecords.map(item => item.id)
  );

  const toggleFilterItem = (index: number): void => {
    const indexOfItem = checkedIndex.indexOf(index);
    const newArray = [...checkedIndex];

    if (indexOfItem >= 0) {
      newArray.splice(indexOfItem, 1);
    } else {
      newArray.push(index);
    }
    setCheckedIndex(newArray);
  };

  useEffect(() => {
    const optionListRecords = allRecords.map(
      (item, index): CountryListItemType => {
        return {
          name: locationRecords[item].name,
          id: item
        };
      }
    );
    setOptionList(optionListRecords);
  }, [locationRecords, allRecords]);

  useEffect(() => {
    if (checkedIndex.length <= 0) {
      setFilteredRecords(
        (allRecords as number[]).map((item: number) => {
          return { active: true, id: item };
        })
      );
    } else {
      setFilteredRecords(
        (allRecords as number[]).map((item: number) => {
          return { active: checkedIndex.includes(item), id: item };
        })
      );
    }
  }, [checkedIndex]);

  const toggleDrawer = (): void => {
    setDrawerState(!drawerState);
  };

  return (
    <>
      <div className={classes.root} onClick={toggleDrawer}>
        <ArrowRightIcon />
      </div>

      <SwipeableDrawer
        disableBackdropTransition={true}
        open={drawerState}
        className={classes.drawer}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <List>
          {optionList.map(
            (item): JSX.Element => (
              <CountryListItem
                key={item.id}
                item={item}
                defaultState={checkedIndex.includes(item.id)}
                toggleFilterItem={toggleFilterItem}
              />
            )
          )}
        </List>
      </SwipeableDrawer>
    </>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    allRecords: state.allRecords,
    filteredRecords: state.filteredRecords,
    locationRecords: state.locationRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setFilteredRecords: filteredRecords.actionCreators.setFilteredRecords,
      resetFilteredRecords: filteredRecords.actionCreators.resetFilteredRecords,
      changeFilteredRecordState:
        filteredRecords.actionCreators.changeFilteredRecordState
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector);
