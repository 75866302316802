import React, { FC, useEffect, useState } from "react";
import {
  DivOverlay,
  FeatureGroup,
  LayersControl,
  Map,
  Marker,
  Popup,
  TileLayer,
  Tooltip
} from "react-leaflet";
import { Grid } from "@material-ui/core";
import { useStyles } from "./Home.styles";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import axios from "axios";
import ReactLeafletSearch from "react-leaflet-search";
import { TimeStepper } from "../molecules/TimeStepper/TimeStepper";
import { ReduxState } from "../../store/reducers";
import { connect } from "react-redux";
import locationRecord, {
  LocationRecord
} from "../../store/states/locationRecord";
import feedRecord, { FeedRecord } from "../../store/states/feedRecord";
import { DivIcon, LatLng } from "leaflet";
import { bindActionCreators, Dispatch } from "redux";
import activeRecord from "../../store/states/activeRecord";
import CountrySelector from "../molecules/CountrySelector/CountrySelector";
import { FilteredRecordType } from "../../store/states/filteredRecords";
import Choropleth from "react-leaflet-choropleth";
import {
  Feature,
  FeatureCollection,
  GeoJSON,
  GeometryCollection
} from "geojson";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";

type StateProps = {
  allRecords: number[];
  filteredRecords: FilteredRecordType[];
  locationRecords: LocationRecord[];
  feedRecords: FeedRecord[];
};

type heatmapPoint = {
  id: number;
  lat: number;
  lon: number;
  intensity: number;
};

const getLocationRecords = (
  allRecords: FilteredRecordType[],
  locationRecord: LocationRecord[],
  feedRecord: FeedRecord[]
): heatmapPoint[] => {
  return allRecords
    .filter(item => item.active)
    .map((item, index) => {
      console.log(locationRecord[item.id]);
      console.log("loop", item.id);
      return {
        id: item.id,
        lat: locationRecord[item.id].latitude,
        lon: locationRecord[item.id].longitude,
        intensity: feedRecord[item.id].intensity
      };
    });
};

const Home: FC<ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>> = ({
  allRecords,
  locationRecords,
  filteredRecords,
  feedRecords,
  setallRecords
}): JSX.Element => {
  const classes = useStyles();
  const [points, setPoints] = useState<heatmapPoint[]>([]);

  useEffect(() => {
    console.log(filteredRecords, "filtered");
    setPoints(
      getLocationRecords(filteredRecords, locationRecords, feedRecords)
    );
  }, [filteredRecords]);

  const style = {
    fillColor: "#F28F3B",
    weight: 2,
    opacity: 1,
    color: "white",
    dashArray: "3",
    fillOpacity: 0.5
  };

  const geoJson: FeatureCollection = {
    type: "FeatureCollection",
    features: points.map(
      (item): Feature => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              item.lat,
              item.lon
            ]
          },
          properties: {
            name: item.id
          }
        };
      }
    )
  };

  const getClusterSize = (size: number) => {
    if (size > 10) {
      return classes.clusterMoreThan10;
    } else if (size >= 5) {
      return classes.cluster5and10;
    } else if (size >= 2) {
      return classes.clusterSmallGroup;
    } else {
      return classes.singleMarker;
    }
  };

  return (
    <Grid container className={classes.container}>
      <CountrySelector />
      {/*<TimeStepper />*/}
      <Map center={[51.505, -0.09]} zoom={3} preferCanvas={true}>
        <LayersControl>
          <LayersControl.BaseLayer name="Base" checked>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; <a href=https://stadiamaps.com/>Stadia Maps</a>, &copy; <a href=https://openmaptiles.org/>OpenMapTiles</a> &copy; <a href=http://openstreetmap.org>OpenStreetMap</a> contributors"
            />
          </LayersControl.BaseLayer>
          <LayersControl.Overlay name="Heatmap">
            <FeatureGroup color="purple">
              <HeatmapLayer
                fitBoundsOnUpdate
                points={points}
                longitudeExtractor={(m: heatmapPoint) => m.lon}
                latitudeExtractor={(m: heatmapPoint) => m.lat}
                intensityExtractor={(m: heatmapPoint) => m.intensity}
              />
            </FeatureGroup>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Choropleth" checked>
            <FeatureGroup color="yellow">
              <MarkerClusterGroup
                iconCreateFunction={cluster => {
                  interface CustomMarker extends Marker {
                    intensity: number;
                  }

                  const childMarkers = cluster.getAllChildMarkers();
                  let sum = 0;

                  childMarkers.forEach(marker => {
                    // @ts-ignore
                    sum += marker.options.intensity;
                  });

                  const size = childMarkers.length * 5;

                  const extraClass = getClusterSize(childMarkers.length);

                  return new DivIcon({
                    html: `<span>${sum}</span>`,
                    className: extraClass
                  });
                }}
                showCoverageOnHover={true}
                spiderfyDistanceMultiplier={15}
                polygonOptions={{
                  smoothFactor: 0,
                  noClip: true
                }}
                animate={true}
                singleMarkerMode={true}
              >
                {points.map(item => {
                  return (
                    <Marker
                      key={item.id}
                      intensity={item.intensity}
                      position={[item.lat, item.lon]}
                    />
                  );
                })}
              </MarkerClusterGroup>
            </FeatureGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </Map>
    </Grid>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    allRecords: state.allRecords,
    filteredRecords: state.filteredRecords,
    locationRecords: state.locationRecords,
    feedRecords: state.feedRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setallRecords: activeRecord.actionCreators.setallRecords
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
