import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "date-fns";
import * as serviceWorker from "./serviceWorker";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "./index.styles";
import "leaflet/dist/leaflet.css";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import store from "./store/store";
import DateFnsUtils from "@date-io/date-fns";

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
      <CssBaseline />
    </ThemeProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
