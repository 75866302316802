import React, { FC, useEffect } from "react";
import "./App.css";
import Home from "./components/pages/Home";
import Regional from "./components/pages/Regional/Regional";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { Menu } from "@material-ui/icons";
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography
} from "@material-ui/core";
import { useStyles } from "./App.styles";
import processData from "./dataProcessor";
import feedRecord, { FeedRecord } from "./store/states/feedRecord";
import { connect } from "react-redux";
import { ReduxState } from "./store/reducers";
import { bindActionCreators, Dispatch } from "redux";
import locationRecord from "./store/states/locationRecord";
import activeRecord from "./store/states/activeRecord";
import filteredRecords from "./store/states/filteredRecords";

type StateProps = {
  feedRecords: FeedRecord[];
};

const App: FC<ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>> = ({
  feedRecords,
  addFeedRecords,
  addLocationRecords,
  addAllRecords,
  setFilteredRecords
}): JSX.Element => {
  const classes = useStyles();

  useEffect(() => {
    processData(
      addFeedRecords,
      addLocationRecords,
      addAllRecords,
      setFilteredRecords
    );
  }, []);

  return (
    <Router>
      <Grid container className={classes.mainGrid}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              className={classes.menuButton}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <Link to="/">Coronews</Link>
            </Typography>
            <div className={classes.endContent}>
              <Button>
                <Link to="/">Worldwide</Link>
              </Button>
              <Button>
                <Link to="regional">Regional</Link>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <section className={classes.content}>
          <Route path="/" exact component={Home} />
          <Route path="/regional" exact component={Regional} />
        </section>
      </Grid>
    </Router>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    feedRecords: state.feedRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      addFeedRecords: feedRecord.actionCreators.addFeedRecords,
      addLocationRecords: locationRecord.actionCreators.addLocationRecords,
      addAllRecords: activeRecord.actionCreators.setallRecords,
      setFilteredRecords: filteredRecords.actionCreators.setFilteredRecords
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
