import { schema } from "normalizr";

const defaultChildOptions: schema.EntityOptions = {
  processStrategy: (value, parent, key) => {
    return { ...value, id: parent.id };
  },
  idAttribute: (value, parent, key) => parent.id
};

const geoLocation = new schema.Entity(
  "locations",
  {},
  {
    ...defaultChildOptions,
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        name: parent.country + (parent.province ? ` - ${parent.province}` : ``),
        latitude: parseFloat(value.latitude),
        longitude: parseFloat(value.longitude)
      };
    }
  }
);

const cases = new schema.Entity(
  "cases",
  {},
  {
    ...defaultChildOptions,
    processStrategy: (value, parent, key) => {
      return {
        ...value,
        id: parent.id,
        updateTimestamp: parent.last_updated,
        intensity: parent.latest.confirmed
      };
    }
  }
);

const historyCases = new schema.Entity("history", {}, defaultChildOptions);

const location = new schema.Entity(
  "feedRecords",
  {
    coordinates: geoLocation,
    latest: cases,
    timelines: historyCases
  },
  {
    processStrategy: (value, parent, key) => {
      return { ...value, intensity: value.latest.confirmed };
    }
  }
);

export default [location];
