import { actionType } from "../stateType.types";

export type FeedRecord = {
  coordinates: number;
  country: string;
  country_code: string;
  id: number;
  last_updated: string;
  latest: number;
  province: string;
  timelines: number;
  intensity: number;
};

// default value
const defaultValue: FeedRecord[] = [];

// constants
const ADD_FEED_RECORDS = "ADD_FEED_RECORDS";

// actions
const addFeedRecords = (record: FeedRecord[]): actionType => {
  return { type: ADD_FEED_RECORDS, payload: record };
};

export default {
  defaultValue,
  actions: {
    ADD_FEED_RECORDS
  },
  actionCreators: {
    addFeedRecords
  }
};
