import { actionType } from "../stateType.types";

export type LocationRecord = {
  latitude: number;
  longitude: number;
  id: number;
  name: string;
};

// default value
const defaultValue: LocationRecord[] = [];

// constants
const ADD_LOCATION_RECORDS = "ADD_LOCATION_RECORDS";

// actions
const addLocationRecords = (record: LocationRecord[]): actionType => {
  return { type: ADD_LOCATION_RECORDS, payload: record };
};

export default {
  defaultValue: defaultValue,
  actions: {
    ADD_LOCATION_RECORDS
  },
  actionCreators: {
    addLocationRecords
  }
};
