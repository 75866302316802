import axios from "axios";
import normalizeData from "./store/normalizer";
import schema from "./store/normalizer/schema";
import feedRecord from "./store/states/feedRecord";
import store from "./store/store";
import { Dispatch } from "redux";
import locationRecord from "./store/states/locationRecord";
import activeRecord from "./store/states/activeRecord";
import filteredRecords, {
  FilteredRecordType
} from "./store/states/filteredRecords";

const processData = (
  addFeedRecords: typeof feedRecord.actionCreators.addFeedRecords,
  addLocationRecords: typeof locationRecord.actionCreators.addLocationRecords,
  setAllRecords: typeof activeRecord.actionCreators.setallRecords,
  setFilteredRecords: typeof filteredRecords.actionCreators.setFilteredRecords
): void => {
  axios
    .get(
      "https://coronavirus-tracker-api.herokuapp.com/v2/locations?timelines=1"
    )
    .then(response => {
      const newData = normalizeData(response.data.locations, schema);
      console.log(newData.entities);
      addFeedRecords(newData.entities.feedRecords);
      addLocationRecords(newData.entities.locations);
      setAllRecords(newData.result);
      setFilteredRecords(
        (newData.result as number[]).map((item: number) => {
          return { active: true, id: item };
        })
      );
    });
};

export default processData;
