import feedRecord, { FeedRecord } from "../states/feedRecord";
import { actionType } from "../stateType.types";
import locationRecord, { LocationRecord } from "../states/locationRecord";
import activeRecord from "../states/activeRecord";
import filteredRecords, { FilteredRecordType } from "../states/filteredRecords";

export type ReduxState = {
  feedRecords: FeedRecord[];
  locationRecords: LocationRecord[];
  allRecords: number[];
  filteredRecords: FilteredRecordType[];
};

const initialState: ReduxState = {
  feedRecords: feedRecord.defaultValue,
  locationRecords: locationRecord.defaultValue,
  allRecords: activeRecord.defaultValue,
  filteredRecords: filteredRecords.defaultValue
};

const rootReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case feedRecord.actions.ADD_FEED_RECORDS:
      return { ...state, feedRecords: action.payload };
    case filteredRecords.actions.SET_FILTERED_RECORDS: {
      return { ...state, filteredRecords: action.payload };
    }
    case filteredRecords.actions.CHANGE_FILTERED_RECORD_STATE: {
      const newState = { ...state };
      newState.filteredRecords[action.payload.recordIndex] =
        action.payload.value;
      return { ...newState };
    }
    case filteredRecords.actions.RESET_FILTERED_RECORDS: {
      return {
        ...state,
        filteredRecords: state.allRecords ? state.allRecords.map(item => {
          return { active: false, id: item };
        }) : []
      };
    }
    case locationRecord.actions.ADD_LOCATION_RECORDS:
      return { ...state, locationRecords: action.payload };
    case activeRecord.actions.SET_ACTIVE_RECORDS: {
      return { ...state, allRecords: action.payload };
    }
    default:
      return { ...state };
  }
};

export default rootReducer;
