import React, { FC } from "react";
import { Input, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ReduxState } from "../../../store/reducers";
import { connect } from "react-redux";
import { FilteredRecordType } from "../../../store/states/filteredRecords";
import { LocationRecord } from "../../../store/states/locationRecord";
import { FeedRecord } from "../../../store/states/feedRecord";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

type StateProps = {
  allRecords: number[];
  filteredRecords: FilteredRecordType[];
  locationRecords: LocationRecord[];
  feedRecords: FeedRecord[];
};

const Regional: FC<ReturnType<typeof mapStateToProps>> = ({
  allRecords,
  locationRecords
}): JSX.Element => {
  console.log(locationRecords);

  return (
    <>
      <Autocomplete
        id="countryBox'"
        options={allRecords.map(number => locationRecords[number])}
        getOptionLabel={option => option.name}
        renderInput={params => (
          <TextField {...params} label="Search for your country" />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
      regional
    </>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    allRecords: state.allRecords,
    filteredRecords: state.filteredRecords,
    locationRecords: state.locationRecords,
    feedRecords: state.feedRecords
  };
};

export default connect(mapStateToProps)(Regional);
