import React, {FC, useEffect, useState} from "react";
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

export type CountryListItemType = {
  name: string;
  id: number;
};

const CountryListItem: FC<{
  item: CountryListItemType;
  defaultState: boolean;
  toggleFilterItem: (index: number) => void;
}> = ({ item, toggleFilterItem, defaultState }): JSX.Element => {
  const [checked, setChecked] = useState<boolean>(defaultState);

  return (
    <ListItem onClick={() => {
        setChecked(!checked);
        toggleFilterItem(item.id);
    }}>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          disableRipple={true}
          tabIndex={-1}
          inputProps={{ "aria-labelledby": item.name }}
        />
      </ListItemIcon>
      <ListItemText>{item.name}</ListItemText>
    </ListItem>
  );
};

export default CountryListItem;
