import {makeStyles, Theme} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    toolbar: {
        flexDirection: "row",

        "& a": {
            color: "#FFFFFF",
            textDecoration: "none"
        }
    },
    appBar: {
        height: "55px"
    },
    content: {
        height: "calc(100vh - 55px)",
        width: "100%",
        position: "relative"
    },
    mainGrid: {
        minHeight: "100vh",
        position: "relative"
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flex: 1,
        textDecoration: "none"
    },
    endContent: {
        justifySelf: "flex-end"
    }
}));
